import { authApiService } from '@/api/authApiService';
import { ApiResponseType } from '@/types/sharedTypes';

export type BankNameResponseType = {
  bank_name: string;
};

export const getBankNameApi = (routingNumber: string): Promise<ApiResponseType<BankNameResponseType>> => {
  const uri = `/reference-books/banks/${routingNumber}/`;

  return authApiService.GET(uri);
};
