import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { AuthTitle } from '@/bundle/Auth/ui/AuthTitle/AuthTitle';
import { getPasswordChangedUrl } from '@/bundle/Auth/ForgotPasswordFlow/PasswordChangedPage/urls/getPasswordChangedUrl';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { useFormik } from 'formik';
import { passwordValidationSchema } from './const/passwordValidationSchema';
import { useBulletValidation } from './hooks/useBulletValidation';
import { getLoginUrl } from '@/bundle/Auth/LoginFlow/LoginPage/urls/getLoginUrl';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { CustomInputWrapper, SetPasswordButtonWrapper } from './styles';
import { FormikPasswordInput } from '@/components/form/fields/FormikPasswordInput/FormikPasswordInput';
import { BulletValidation } from './ui/BulletValidation/BulletValidation';
import { Button } from '@/components/Button/Button';
import { setPassword } from './api';
import { Notification } from '@/components/Notification/Notification';
import { AuthLayout } from '@/bundle/Auth/ui/AuthLayout/AuthLayout';
import { AuthStep } from '@/bundle/Auth/ui/AuthStep/AuthStep';
import { getSetPasswordLinkExpiredUrl } from '../SetPasswordLinkExpiredPage/urls/getSetPasswordLinkExpiredUrl';
import { ACTIVATION_TOKEN_INVALID_RESPONSE } from '@/api/const';
import { useEffect } from 'react';

export const SetInitialPasswordPage = () => {
  const navigate = useNavigate();
  const { userId, token } = useParams();

  const { mutate, data, isPending } = useMutation({
    mutationKey: ['set_password'],
    mutationFn: (password: string) => {
      return setPassword(userId, token, password);
    },
    onSuccess(setPasswordResponse) {
      const error = setPasswordResponse?.error;

      if (error) {
        if (error?.error_content === ACTIVATION_TOKEN_INVALID_RESPONSE) {
          navigate(getSetPasswordLinkExpiredUrl());
        }

        return;
      }

      amplitudeService.logEvent(AMPLITUDE_EVENTS.SetPasswordSuccess);

      navigate(getPasswordChangedUrl());
    },
  });

  useEffect(() => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.SetInitialPasswordRedirect);
  }, []);

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    onSubmit: (values: any) => {
      mutate(values.password);
    },
    enableReinitialize: true,
    validationSchema: passwordValidationSchema,
  });

  const { isFullValid } = useBulletValidation(formik.values?.password);
  const isGrey = !formik.touched.password;
  const isValid = isFullValid && formik.isValid;

  const navigateToLogin = () => {
    navigate(getLoginUrl());
  };

  const error = data?.error?.error_content as string;

  return (
    <AuthLayout>
      <AuthStep>
        <AuthTitle>Set Your Password</AuthTitle>

        {error && (
          <Notification variant='error' mb='24px'>
            {error}
          </Notification>
        )}

        <FormikForm value={formik}>
          <CustomInputWrapper>
            <FormikPasswordInput
              label={'New Password*'}
              placeholder={'Enter Your New Password'}
              name='password'
              apiError={data?.error}
              maxLength={30}
              hasToggleEye
            />
          </CustomInputWrapper>
          <BulletValidation value={formik?.values?.password} isGrey={isGrey} />
          <FormikPasswordInput
            label={'Confirm New Password*'}
            placeholder={'Confirm Your Password'}
            name='passwordConfirmation'
            apiError={data?.error}
            maxLength={30}
            isCopyPasteDisabled
            isConfirmTicks
          />
          <SetPasswordButtonWrapper>
            <Button isLoading={isPending} onClick={formik.handleSubmit} type='submit' disabled={!isValid}>
              Submit
            </Button>
            <Button color={'secondary'} onClick={navigateToLogin}>
              Return to Login
            </Button>
          </SetPasswordButtonWrapper>
        </FormikForm>
      </AuthStep>
    </AuthLayout>
  );
};
