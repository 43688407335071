import { useField } from 'formik';
import { getFieldError } from '@/helpers/formHelpers/formHelpers';
import { ChangeEvent } from 'react';
import { ResponseErrorType } from '@/types/sharedTypes';
import { Input, InputType } from '../Input/Input';

type FormikInputNewType = Omit<InputType, 'apiError'> & {
  apiError?: ResponseErrorType;
  isConfirmTicks?: boolean;
};

export const FormikInputNew = ({ name, apiError, isConfirmTicks, ...rest }: FormikInputNewType) => {
  const [field, meta, { setValue }] = useField(name);

  const { apiError: apiErrorMessage, error } = getFieldError(name, meta, apiError);
  const hasConfirmTicks = isConfirmTicks && field.value?.trim() && !meta.error;

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <Input
      {...field}
      onChange={onChangeHandler}
      hasConfirmTicks={hasConfirmTicks}
      error={error}
      apiError={apiErrorMessage}
      {...rest}
    />
  );
};
