import { AuthStep } from '@/bundle/Auth/ui/AuthStep/AuthStep';
import {
  Resend,
  ResendWrapper,
  TwoFAButtonsWrapper,
  TwoFAFormWrapper,
  TwoFALabel,
  TwoFAMainMessage,
  TwoFAMessage,
  TwoFAPhone,
  TwoFATitle,
} from './styles';
import { Notification } from '@/components/Notification/Notification';
import { CODE_LENGTH, TwoFaCodeInput } from '../TwoFaCodeInput';
import { Timer } from '@/bundle/Auth/LoginFlow/TwoFAPage/ui/Timer/Timer';
import { Button } from '@/components/Button/Button';
import { FormEvent, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { authTokenService } from '@/bundle/Auth/LoginFlow/LoginPage/service/authTokenService';
import { decodeToken } from '@/helpers/tokenHelpers';
import { AuthTokenType, ResponseErrorType } from '@/types/sharedTypes';
import { phoneFormatter } from '@/helpers/formatHelpers';
import { useNavigate } from 'react-router-dom';
import { getLoginUrl } from '@/bundle/Auth/LoginFlow/LoginPage/urls/getLoginUrl';
import { Loader } from '@/components/Loader/Loader';

const INITIAL_CODE = Array(CODE_LENGTH).fill('');
const REQUEST_TIMER_DELAY = 59;

const convertSecondsToMinutes = (secondsTimer: number) => {
  const minutes = Math.floor(secondsTimer / 60);
  const seconds = secondsTimer % 60;

  return {
    seconds,
    minutes,
  };
};

type TwoFAFormType = {
  isSendCodeLoading: boolean;
  isTwoFALoading: boolean;
  onConfirm: (code: string) => void;
  onResend: () => void;
  twoFAError?: ResponseErrorType;
  sendCodeError?: ResponseErrorType;
};

export const TwoFAForm = forwardRef((props: TwoFAFormType, ref) => {
  const { isSendCodeLoading, isTwoFALoading, twoFAError, sendCodeError, onConfirm, onResend } = props;
  const navigate = useNavigate();
  const [code, setCode] = useState(INITIAL_CODE);
  const [phone, setPhone] = useState('');
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isDisabledSendCode, setIsDisabledSendCode] = useState(false);
  const [codeChanged, setCodeChanged] = useState(false);

  useImperativeHandle(
    ref,
    () => {
      return {
        resetTimer(retryAfter: string | null) {
          const timerValue = retryAfter ? Number(retryAfter) : REQUEST_TIMER_DELAY;
          const { seconds: secondsValue, minutes: minutesValues } = convertSecondsToMinutes(timerValue);

          setMinutes(minutesValues);
          setSeconds(secondsValue);
          setIsDisabledSendCode(true);
        },

        resetCodeChanged() {
          setCodeChanged(false);
        },
      };
    },
    []
  );

  const changeCode = (newCode: string[]) => {
    setCodeChanged(true);
    setCode(newCode);
  };

  const navigateToLogin = () => {
    navigate(getLoginUrl());
  };

  const setUserPhone = () => {
    const tokens = authTokenService.getTokens();

    if (!tokens) return;

    const { mobile_phone } = decodeToken<AuthTokenType>(tokens.access);

    setPhone(phoneFormatter(mobile_phone));
  };

  useEffect(() => {
    setUserPhone();
    onResend();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (seconds === 0 && minutes === 0) {
      setIsDisabledSendCode(false);
    }
  }, [minutes, seconds]);

  const startTimer = () => {
    if (minutes === 0 && seconds === 0) {
      onResend();
    }

    setIsDisabledSendCode(true);
  };

  const getFormattedCode = () => {
    return code.join('');
  };

  const confirmTwoFA = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formattedCode = getFormattedCode();

    if (formattedCode.length !== CODE_LENGTH) {
      return;
    }

    onConfirm(formattedCode);
  };

  const isDisabledSubmit = getFormattedCode().length !== CODE_LENGTH;
  const apiError = (twoFAError?.error_content || sendCodeError?.error_content) as string;
  const hasInputError = !!twoFAError?.error_content && !codeChanged;

  return (
    <AuthStep width='508px'>
      <TwoFATitle>Enter Code</TwoFATitle>
      <TwoFAMainMessage>Enter code sent on mobile phone number</TwoFAMainMessage>
      <TwoFAPhone>{phone}</TwoFAPhone>
      <TwoFAMessage>The code is valid for 5 minutes.</TwoFAMessage>

      {apiError && (
        <Notification variant='error' mt='12px' mb='12px'>
          {apiError}
        </Notification>
      )}

      <form onSubmit={confirmTwoFA}>
        <TwoFAFormWrapper>
          <TwoFALabel>Verification code</TwoFALabel>
          <TwoFaCodeInput code={code} onChange={changeCode} hasError={hasInputError} />
          <ResendWrapper>
            {isDisabledSendCode && (
              <Timer minutes={minutes} setMinutes={setMinutes} seconds={seconds} setSeconds={setSeconds} />
            )}
            {isSendCodeLoading ? (
              <Loader inline background='transparent' />
            ) : (
              <Resend type='button' onClick={startTimer} isDisabled={isDisabledSendCode}>
                Resend
              </Resend>
            )}
          </ResendWrapper>
        </TwoFAFormWrapper>
        <TwoFAButtonsWrapper>
          <Button type='submit' disabled={isDisabledSubmit} isLoading={isTwoFALoading} onClick={confirmTwoFA}>
            Submit
          </Button>
          <Button color='secondary' onClick={navigateToLogin}>
            Back
          </Button>
        </TwoFAButtonsWrapper>
      </form>
    </AuthStep>
  );
});
