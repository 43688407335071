import mapValues from 'lodash/mapValues';
import { PAYMENT_TYPE_DB } from '@/const/shared';
import {
  BANK_ABA_NUMBER_VALIDATOR,
  CONFIRM_BANK_ABA_NUMBER_VALIDATOR,
  CONFIRM_BANK_ACCOUNT_NUMBER,
  CONFIRM_INTERMEDIARY_BANK_ABA_NUMBER_VALIDATOR,
  CONFIRM_INTERMEDIARY_BANK_ACCOUNT_NUMBER,
  CONFIRM_INTERMEDIARY_SWIFT_CODE_VALIDATOR,
  CONFIRM_SWIFT_CODE_VALIDATOR,
  PHONE_NUMBER_VALIDATOR,
  REQUIRED_TEXT_FIELD_VALIDATOR,
  STRING_VALIDATOR,
  SWIFT_CODE_VALIDATOR,
  TEXT_FIELD_VALIDATOR,
} from '@/helpers/formHelpers/validators';
import { isDomesticPaymentType, isInternationalPaymentType } from '@/helpers/paymentDetailsHelpers';
import { PaymentType } from '@/types/paymentDetailsTypes';
import { RequiredStringSchema } from 'yup/lib/string';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

export const PROVIDE_PAYMENT_TYPE_OPTIONS = [
  { value: PAYMENT_TYPE_DB.DOMESTIC, label: 'Domestic Payment' },
  { value: PAYMENT_TYPE_DB.INTERNATIONAL, label: 'International Payment' },
];

export type PaymentDetailsInputConfigType = {
  name: string;
  confirmName: string;
  intermediaryName: string;
  confirmIntermediaryName: string;
  label: string;
  confirmLabel: string;
  placeholder: string;
};

export const getPaymentDetailsInputConfig = (paymentType: PaymentType): PaymentDetailsInputConfigType => {
  const isDomesticPayment = isDomesticPaymentType(paymentType);
  const isInternationalPayment = isInternationalPaymentType(paymentType);

  if (isDomesticPayment) {
    return {
      name: 'bank_aba_number',
      confirmName: 'confirm_bank_aba_number',
      intermediaryName: 'intermediary_bank_aba_number',
      confirmIntermediaryName: 'confirm_intermediary_bank_aba_number',
      label: 'Routing / ABA Number*',
      confirmLabel: 'Confirm Routing / ABA Number*',
      placeholder: 'Enter Number',
    };
  }

  if (isInternationalPayment) {
    return {
      name: 'bank_swift_code',
      confirmName: 'confirm_bank_swift_code',
      intermediaryName: 'intermediary_bank_swift_code',
      confirmIntermediaryName: 'confirm_intermediary_bank_swift_code',
      label: 'Swift Code*',
      confirmLabel: 'Confirm Swift Code*',
      placeholder: 'Enter Code',
    };
  }
};

export type IntermediaryProvidePaymentFormType = {
  intermediary_bank_aba_number: string;
  confirm_intermediary_bank_aba_number: string;
  intermediary_bank_swift_code: string;
  confirm_intermediary_bank_swift_code?: string;
  intermediary_bank_name: string;
  intermediary_bank_account_number: string;
  confirm_intermediary_bank_account_number: string;
  intermediary_bank_address: string;
  intermediary_bank_phone_number: string;
  is_intermediary_bank_name_verified: boolean;
};

export type ProvidePaymentFormType = IntermediaryProvidePaymentFormType & {
  recipient_name: string;
  account_name: string;
  recipient_address: string;
  bank_aba_number?: string;
  confirm_bank_aba_number?: string;
  bank_swift_code?: string;
  confirm_bank_swift_code?: string;
  bank_name: string;
  bank_account_number: string;
  confirm_bank_account_number: string;
  bank_address: string;
  bank_phone_number: string;
  is_bank_name_verified: boolean;
};

export const INTERMEDIARY_PROVIDE_PAYMENT_DETAILS_INITIAL_VALUES: IntermediaryProvidePaymentFormType = {
  intermediary_bank_aba_number: '',
  confirm_intermediary_bank_aba_number: '',
  intermediary_bank_swift_code: '',
  confirm_intermediary_bank_swift_code: '',
  intermediary_bank_name: '',
  intermediary_bank_account_number: '',
  confirm_intermediary_bank_account_number: '',
  intermediary_bank_address: '',
  intermediary_bank_phone_number: '',
  is_intermediary_bank_name_verified: false,
};

export const INTERMEDIARY_PROVIDE_PAYMENT_DETAILS_INITIAL_TOUCHED = mapValues(
  INTERMEDIARY_PROVIDE_PAYMENT_DETAILS_INITIAL_VALUES,
  () => false
);

export const PROVIDE_PAYMENT_DETAILS_INITIAL_VALUES: ProvidePaymentFormType = {
  recipient_name: '',
  account_name: '',
  recipient_address: '',
  bank_name: '',
  bank_account_number: '',
  confirm_bank_account_number: '',
  bank_address: '',
  bank_phone_number: '',
  bank_aba_number: '',
  confirm_bank_aba_number: '',
  bank_swift_code: '',
  confirm_bank_swift_code: '',
  is_bank_name_verified: false,
  ...INTERMEDIARY_PROVIDE_PAYMENT_DETAILS_INITIAL_VALUES,
};

export const getPaymentDetailsPayloadValues = <T extends ProvidePaymentFormType>(
  values: T,
  paymentType: PaymentType,
  hasIntermediary: boolean
) => {
  const isDomesticPayment = isDomesticPaymentType(paymentType);
  const isInternationalPayment = isInternationalPaymentType(paymentType);

  const {
    bank_aba_number,
    confirm_bank_aba_number,
    bank_swift_code,
    confirm_bank_swift_code,
    intermediary_bank_name,
    intermediary_bank_address,
    intermediary_bank_account_number,
    confirm_intermediary_bank_account_number,
    intermediary_bank_phone_number,
    intermediary_bank_aba_number,
    confirm_intermediary_bank_aba_number,
    intermediary_bank_swift_code,
    confirm_intermediary_bank_swift_code,
    is_bank_name_verified,
    is_intermediary_bank_name_verified,
    ...restValues
  } = values;

  const paymentDetailsValues = {
    ...restValues,
    ...(isDomesticPayment && {
      bank_aba_number,
      confirm_bank_aba_number,
      is_bank_name_verified,
    }),
    ...(isInternationalPayment && {
      bank_swift_code,
      confirm_bank_swift_code,
    }),
  };

  const intermediaryPaymentDetailsValues = {
    intermediary_bank_name,
    intermediary_bank_address,
    intermediary_bank_account_number,
    confirm_intermediary_bank_account_number,
    intermediary_bank_phone_number,
    ...(isDomesticPayment && {
      intermediary_bank_aba_number,
      confirm_intermediary_bank_aba_number,
      is_intermediary_bank_name_verified,
    }),
    ...(isInternationalPayment && {
      intermediary_bank_swift_code,
      confirm_intermediary_bank_swift_code,
    }),
  };

  return {
    ...paymentDetailsValues,
    ...(hasIntermediary && intermediaryPaymentDetailsValues),
  };
};

export const getConditionalValidationRule = (
  condition: boolean,
  validator: RequiredStringSchema<string, AnyObject>
) => {
  return STRING_VALIDATOR.when([], {
    is: () => condition,
    then: validator,
    otherwise: (schema) => schema.nullable(),
  });
};

export const getPaymentDetailsSchema = (paymentType: PaymentType, hasIntermediary: boolean) => {
  const isDomesticPayment = isDomesticPaymentType(paymentType);
  const isInternationalPayment = isInternationalPaymentType(paymentType);

  return Yup.object().shape({
    recipient_name: REQUIRED_TEXT_FIELD_VALIDATOR,
    account_name: TEXT_FIELD_VALIDATOR,
    recipient_address: REQUIRED_TEXT_FIELD_VALIDATOR,
    bank_name: TEXT_FIELD_VALIDATOR,
    bank_account_number: REQUIRED_TEXT_FIELD_VALIDATOR,
    confirm_bank_account_number: CONFIRM_BANK_ACCOUNT_NUMBER,
    bank_address: TEXT_FIELD_VALIDATOR,
    bank_phone_number: PHONE_NUMBER_VALIDATOR,
    bank_aba_number: getConditionalValidationRule(isDomesticPayment, BANK_ABA_NUMBER_VALIDATOR),
    confirm_bank_aba_number: getConditionalValidationRule(isDomesticPayment, CONFIRM_BANK_ABA_NUMBER_VALIDATOR),
    bank_swift_code: getConditionalValidationRule(isInternationalPayment, SWIFT_CODE_VALIDATOR),
    confirm_bank_swift_code: getConditionalValidationRule(isInternationalPayment, CONFIRM_SWIFT_CODE_VALIDATOR),

    intermediary_bank_aba_number: getConditionalValidationRule(
      isDomesticPayment && hasIntermediary,
      BANK_ABA_NUMBER_VALIDATOR
    ),
    confirm_intermediary_bank_aba_number: getConditionalValidationRule(
      isDomesticPayment && hasIntermediary,
      CONFIRM_INTERMEDIARY_BANK_ABA_NUMBER_VALIDATOR
    ),
    intermediary_bank_swift_code: getConditionalValidationRule(
      isInternationalPayment && hasIntermediary,
      SWIFT_CODE_VALIDATOR
    ),
    confirm_intermediary_bank_swift_code: getConditionalValidationRule(
      isInternationalPayment && hasIntermediary,
      CONFIRM_INTERMEDIARY_SWIFT_CODE_VALIDATOR
    ),
    intermediary_bank_name: getConditionalValidationRule(hasIntermediary, TEXT_FIELD_VALIDATOR),
    intermediary_bank_account_number: getConditionalValidationRule(hasIntermediary, REQUIRED_TEXT_FIELD_VALIDATOR),
    confirm_intermediary_bank_account_number: getConditionalValidationRule(
      hasIntermediary,
      CONFIRM_INTERMEDIARY_BANK_ACCOUNT_NUMBER
    ),
    intermediary_bank_address: getConditionalValidationRule(hasIntermediary, TEXT_FIELD_VALIDATOR),
    intermediary_bank_phone_number: getConditionalValidationRule(hasIntermediary, PHONE_NUMBER_VALIDATOR),
  });
};
