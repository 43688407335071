import styled, { keyframes } from 'styled-components';
import { flexCenter, getButtonWidth } from '@/styles/layout';
import { COLORS } from '@/styles/colors';
import { media } from '@/styles/responsive';

const pulseAnimation = keyframes`
  0% { opacity: 1; }
  50% { background-color: ${COLORS.black}; }
  100% { opacity: 1; }
`;

export const LoaderWrapper = styled.div<{
  width?: number | string;
  size?: 'large' | 'medium' | 'small';
  background?: string;
  mobileStretch?: boolean;
  inline?: boolean;
}>`
  ${flexCenter};
  border-radius: 10px;
  background-color: ${({ background }) => (background ? background : COLORS.grey[300])};
  width: ${({ width }) => getButtonWidth(width)};
  height: 50px;

  ${({ size }) =>
    size === 'medium' &&
    `
    height: 40px;
  `};

  ${({ size }) =>
    size === 'small' &&
    `
    height: 20px;
  `};

  ${({ inline }) =>
    inline &&
    `
    width: auto;
    height: auto;
  `};

  ${media.mobile`
    ${({ mobileStretch }) =>
      mobileStretch &&
      `
      width: 100%;
    `};
  `}
`;

export const LoaderCircles = styled.div`
  ${flexCenter};
`;

export const Circle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 5px;
  background-color: ${COLORS.grey[400]};
  animation: ${pulseAnimation} 1s ease-in-out infinite;
`;

export const Circle1 = styled(Circle)``;
export const Circle2 = styled(Circle)`
  animation-delay: 0.2s;
`;
export const Circle3 = styled(Circle)`
  animation-delay: 0.4s;
`;
