import {
  getConditionalValidationRule,
  ProvidePaymentFormType,
} from '@/bundle/shared/components/ProvidePaymentDetailsForm/const';
import {
  BANK_ABA_NUMBER_VALIDATOR,
  PHONE_NUMBER_VALIDATOR,
  REQUIRED_TEXT_FIELD_VALIDATOR,
  SWIFT_CODE_VALIDATOR,
  TEXT_FIELD_VALIDATOR,
} from '@/helpers/formHelpers/validators';
import { isDomesticPaymentType, isInternationalPaymentType } from '@/helpers/paymentDetailsHelpers';
import { PaymentType } from '@/types/paymentDetailsTypes';
import * as Yup from 'yup';

type OptionsType = {
  id: string;
  name: string;
};

export type SelectDepositAccountFormType = Omit<
  ProvidePaymentFormType,
  | 'confirm_bank_aba_number'
  | 'confirm_bank_swift_code'
  | 'confirm_bank_account_number'
  | 'confirm_intermediary_bank_aba_number'
  | 'confirm_intermediary_bank_swift_code'
  | 'confirm_intermediary_bank_account_number'
>;

export const getDepositAccountOptions = (depositAccounts: OptionsType[]) => {
  return depositAccounts?.map((item) => {
    return { value: item.id, label: item.name };
  });
};

export const getPaymentDetailsValidationSchema = (paymentType: PaymentType, hasIntermediary: boolean) => {
  const isDomesticPayment = isDomesticPaymentType(paymentType);
  const isInternationalPayment = isInternationalPaymentType(paymentType);

  return Yup.object().shape({
    recipient_name: REQUIRED_TEXT_FIELD_VALIDATOR,
    account_name: TEXT_FIELD_VALIDATOR,
    recipient_address: REQUIRED_TEXT_FIELD_VALIDATOR,
    bank_name: TEXT_FIELD_VALIDATOR,
    bank_account_number: REQUIRED_TEXT_FIELD_VALIDATOR,
    bank_address: TEXT_FIELD_VALIDATOR,
    bank_phone_number: PHONE_NUMBER_VALIDATOR,
    bank_aba_number: getConditionalValidationRule(isDomesticPayment, BANK_ABA_NUMBER_VALIDATOR),
    bank_swift_code: getConditionalValidationRule(isInternationalPayment, SWIFT_CODE_VALIDATOR),
    intermediary_bank_aba_number: getConditionalValidationRule(
      isDomesticPayment && hasIntermediary,
      BANK_ABA_NUMBER_VALIDATOR
    ),
    intermediary_bank_swift_code: getConditionalValidationRule(
      isInternationalPayment && hasIntermediary,
      SWIFT_CODE_VALIDATOR
    ),
    intermediary_bank_name: getConditionalValidationRule(hasIntermediary, TEXT_FIELD_VALIDATOR),
    intermediary_bank_account_number: getConditionalValidationRule(hasIntermediary, REQUIRED_TEXT_FIELD_VALIDATOR),
    intermediary_bank_address: getConditionalValidationRule(hasIntermediary, TEXT_FIELD_VALIDATOR),
    intermediary_bank_phone_number: getConditionalValidationRule(hasIntermediary, PHONE_NUMBER_VALIDATOR),
  });
};

export const getPaymentDetailsPayloadValues = (
  values: SelectDepositAccountFormType,
  paymentType: PaymentType,
  hasIntermediary: boolean
) => {
  const isDomesticPayment = isDomesticPaymentType(paymentType);
  const isInternationalPayment = isInternationalPaymentType(paymentType);

  const {
    bank_aba_number,
    bank_swift_code,
    intermediary_bank_name,
    intermediary_bank_address,
    intermediary_bank_account_number,
    intermediary_bank_phone_number,
    intermediary_bank_aba_number,
    intermediary_bank_swift_code,
    ...restValues
  } = values;

  const paymentDetailsValues = {
    ...restValues,
    ...(isDomesticPayment && {
      bank_aba_number,
    }),
    ...(isInternationalPayment && {
      bank_swift_code,
    }),
  };

  const intermediaryPaymentDetailsValues = {
    intermediary_bank_name,
    intermediary_bank_address,
    intermediary_bank_account_number,
    intermediary_bank_phone_number,
    ...(isDomesticPayment && {
      intermediary_bank_aba_number,
    }),
    ...(isInternationalPayment && {
      intermediary_bank_swift_code,
    }),
  };

  return {
    ...paymentDetailsValues,
    ...(hasIntermediary && intermediaryPaymentDetailsValues),
  };
};
