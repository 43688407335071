import { Box } from '@/components/Box/Box';
import { ReactComponent as VerifiedIcon } from '@/images/VerifiedIcon.svg';
import { Tooltip } from '@/components/Tooltip/Tooltip';

type BankNameType = {
  name: string;
  isVerified: boolean;
};

export const BankName = ({ name, isVerified }: BankNameType) => {
  return (
    <Box display='flex' alignItems='center' columnGap='8px'>
      {isVerified && (
        <>
          <VerifiedIcon data-tooltip-id='verified-bank-tooltip' />
          <Tooltip id='verified-bank-tooltip'>Bank Verified by WireVault</Tooltip>
        </>
      )}
      {name}
    </Box>
  );
};
