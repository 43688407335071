import { createDomesticDepositAccountApi } from '@/api/v1/organization/deposit-accounts/createDomesticDepositAccountApi';
import { createInternationalDepositAccountApi } from '@/api/v1/organization/deposit-accounts/createInternationalDepositAccountApi';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { getBankNameApi } from '@/api/v1/reference-books/getBankNameApi';

export const createDepositAccount = async (payload, isDomesticPayment: boolean) => {
  try {
    const response = isDomesticPayment
      ? await createDomesticDepositAccountApi(payload)
      : await createInternationalDepositAccountApi(payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const loadBankName = async (routingNumber: string) => {
  try {
    const response = await getBankNameApi(routingNumber);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
