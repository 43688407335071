import { FormWrapper, Row, HighlightRow, HeaderAction, HighlightRowTitle } from './styles';
import { FormikPhoneInput } from '@/components/form/fields/FormikPhoneInput/FormikPhoneInput';
import { ResponseErrorType } from '@/types/sharedTypes';
import { PaymentDetailsInputConfigType } from '../../const';
import { FieldRow } from '@/components/form/FieldRow/FieldRow';
import { ReactComponent as VerifiedIcon } from '../../../../../../images/VerifiedIcon.svg';
import { FormikInputNew } from '@/components/form/fields/FormikInputNew/FormikInputNew';
import { Box } from '@/components/Box/Box';
import { InfoTooltipBadge } from '../../../InfoTooltipBadge/InfoTooltipBadge';

type AddIntermediaryBankFormType = {
  isOpen: boolean;
  onOpen: (open: boolean) => void;
  paymentDetailsInputConfig: PaymentDetailsInputConfigType;
  apiError: ResponseErrorType;
  isDomesticPayment: boolean;
  isVerifiedBank?: boolean;
  isDisabledBankName?: boolean;
};

export const AddIntermediaryBankForm = ({
  isOpen,
  onOpen,
  paymentDetailsInputConfig,
  apiError,
  isDomesticPayment,
  isVerifiedBank,
  isDisabledBankName,
}: AddIntermediaryBankFormType) => {
  return (
    <FormWrapper>
      {isOpen ? (
        <>
          <HighlightRow>
            <HighlightRowTitle>Intermediary Bank</HighlightRowTitle>
            <HeaderAction onClick={() => onOpen(false)}>Remove</HeaderAction>
          </HighlightRow>

          <FieldRow columns={[6, 6]}>
            <FormikInputNew
              name={paymentDetailsInputConfig.intermediaryName}
              type='password'
              label={paymentDetailsInputConfig.label}
              placeholder={paymentDetailsInputConfig.placeholder}
              apiError={apiError}
              autoComplete='new-password'
            />
            <FormikInputNew
              name={paymentDetailsInputConfig.confirmIntermediaryName}
              label={paymentDetailsInputConfig.confirmLabel}
              placeholder={paymentDetailsInputConfig.placeholder}
              apiError={apiError}
              autoComplete='off'
              isCopyPasteDisabled
              isConfirmTicks={true}
            />
          </FieldRow>

          <FormikInputNew
            name='intermediary_bank_name'
            label={
              <Box display='flex' alignItems='center' columnGap='6px'>
                Bank Name
                {isDomesticPayment && (
                  <InfoTooltipBadge>Bank Name field is disabled until Routing / ABA Number is filled</InfoTooltipBadge>
                )}
              </Box>
            }
            placeholder='Enter Bank Name'
            apiError={apiError}
            disabled={isDisabledBankName}
            after={isVerifiedBank && <VerifiedIcon />}
          />

          <FieldRow columns={[6, 6]}>
            <FormikInputNew
              name='intermediary_bank_account_number'
              type='password'
              label='Account Number*'
              placeholder='Enter Number'
              apiError={apiError}
            />
            <FormikInputNew
              name='confirm_intermediary_bank_account_number'
              label='Confirm Account Number*'
              placeholder='Enter Number'
              apiError={apiError}
              autoComplete='off'
              isCopyPasteDisabled
              isConfirmTicks={true}
            />
          </FieldRow>

          <FormikInputNew
            name='intermediary_bank_address'
            label='Bank Address'
            placeholder='Enter Bank Address'
            apiError={apiError}
          />
          <FormikPhoneInput
            name='intermediary_bank_phone_number'
            label='Bank Phone Number'
            placeholder='Enter Bank Phone Number With Country Code'
            apiError={apiError}
          />
        </>
      ) : (
        <Row>
          <HeaderAction onClick={() => onOpen(true)}>+ Add Intermediary Bank</HeaderAction>
        </Row>
      )}
    </FormWrapper>
  );
};
