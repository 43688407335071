import { Notification } from '@/components/Notification/Notification';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Widget } from '@/components/Widget/Widget';
import { DepositAccountLink } from '../DepositAccountLink/DepositAccountLink';
import { Separator } from '../../styles';
import { isDepositorUnassignedWireStatus } from '@/helpers/wireHelpers';
import { WireStatusType } from '@/types/wireTypes';
import { PaymentDetailsGeneralType } from '@/types/paymentDetailsTypes';
import { PaymentDetailsData } from '@/bundle/shared/components/PaymentDetailsData/PaymentDetailsData';

const DeleteTooltip = () => {
  return <Tooltip id='delete-tooltip'>Reset Wire Info</Tooltip>;
};

type PaymentDetailsType = {
  paymentDetails: PaymentDetailsGeneralType;
  hasIntermediaryBank: boolean;
  wireStatus: WireStatusType;
  hasAssignedDepositor: boolean;
  onReset: () => void;
};

export const PaymentDetails = ({ paymentDetails, wireStatus, hasAssignedDepositor, onReset }: PaymentDetailsType) => {
  const isDepositorUnassignedWire = isDepositorUnassignedWireStatus(wireStatus);
  const hasBlockchainData = paymentDetails?.is_blockchain_payment_details_data_from_bc;
  const hasDatabaseData = paymentDetails?.is_blockchain_payment_details_data_from_bc === false && hasAssignedDepositor;

  return (
    <Widget
      title='Wire Info'
      hasDeleteButton={isDepositorUnassignedWire}
      onToggle={onReset}
      tooltip={<DeleteTooltip />}
    >
      {hasBlockchainData && (
        <Notification variant='success' fontVariant='medium' verticalAlign='center' mb='15px'>
          Stored in the WireVault blockchain.
        </Notification>
      )}

      {hasDatabaseData && (
        <Notification variant='info-secondary' mb='15px'>
          The WireVault blockchain is unavailable. The wire info cannot be confirmed at this time. Please try again
          later.
        </Notification>
      )}
      <PaymentDetailsData paymentDetails={paymentDetails} />
      <Separator />
      <DepositAccountLink depositAccount={paymentDetails?.deposit_account} />
    </Widget>
  );
};
