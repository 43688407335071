import { authApiService } from '@/api/authApiService';
import { ApiResponseType } from '@/types/sharedTypes';

export type DomesticPaymentType = {
  id: string;
  date_created: string;
  date_modified: string;
  recipient_name: string;
  account_name: string;
  bank_aba_number: string;
  bank_name: string;
  bank_account_number: string;
  bank_address: string;
  bank_phone_number: string;
  intermediary_bank_aba_number?: string;
  intermediary_bank_name?: string;
  intermediary_bank_account_number?: string;
  intermediary_bank_address?: string;
  intermediary_bank_phone_number?: string;
  is_bank_name_verified: boolean;
  is_intermediary_bank_name_verified: boolean;
};

export const updateWirePaymentDetailsDomesticApi = (
  wireId: string,
  data: any
): Promise<ApiResponseType<DomesticPaymentType>> => {
  const uri = `/recipient/wires/${wireId}/payment-details/domestic/`;

  return authApiService.POST(uri, data);
};
