import { FormGeneratorWrapper, FieldWrapper, RadioFieldWrapper, DividerWrapper } from './styles';
import { FormikInput } from '../fields/FormikInput/FormikInput';
import { ResponseErrorType } from '@/types/sharedTypes';
import { FormikSelectNew } from '../fields/FormikSelectNew/FormikSelectNew';
import { FormikDatePicker } from '../FormikDatePicker/FormikDatePicker';
import { FormikTextArea } from '../fields/FormikTextArea/FormikTextArea';
import { FormDivider } from '../FormDivider';
import { FormikPhoneInput } from '../fields/FormikPhoneInput/FormikPhoneInput';
import { FormikSelectableTabs } from '../fields/FormikSelectableTabs/FormikSelectableTabs';
import { FormikSwitchToggle } from '../fields/FormikSwitchToggle/FormikSwitchToggle';
import { FormikRadioGroup } from '../fields/FormikRadioGroup/FormikRadioGroup';

interface FormGeneratorType {
  config: any[];
  apiError?: ResponseErrorType;
  disableForm?: boolean;
}

const getInputTypeAdditionalProps = (type) => {
  if (type === 'confirm') {
    return {
      isCopyPasteDisabled: true,
      isConfirmTicks: true,
      autoComplete: 'off',
    };
  }

  return null;
};

export const FormGeneratorNew = ({ config, apiError, disableForm }: FormGeneratorType) => {
  return (
    <FormGeneratorWrapper>
      {config.map((configItem, index) => {
        const { type, name, label, placeholder, autoComplete, isCopyPasteDisabled, options, column, disabled, meta } =
          configItem;
        const key = `${index}_${name}`;
        const isDisabledField = disableForm || disabled;

        switch (type) {
          // Type <external> renders as individual component outside of form generator
          case 'external':
            return null;

          case 'radio':
            return (
              <RadioFieldWrapper key={key}>
                <FormikRadioGroup name={name} label={label} options={options} />
              </RadioFieldWrapper>
            );

          case 'select':
            return (
              <FieldWrapper key={key} column={column}>
                <FormikSelectNew
                  name={name}
                  label={label}
                  placeholder={placeholder}
                  options={options}
                  apiError={apiError}
                />
              </FieldWrapper>
            );

          case 'datepicker':
            return (
              <FieldWrapper key={key} column={column}>
                <FormikDatePicker
                  name={name}
                  label={label}
                  placeholder={placeholder}
                  hasErrorComponent={meta?.hasErrorComponent}
                />
              </FieldWrapper>
            );

          case 'phone_input':
            return (
              <FieldWrapper key={key} column={column}>
                <FormikPhoneInput
                  name={name}
                  label={label}
                  placeholder={placeholder}
                  apiError={apiError}
                  disabled={isDisabledField}
                />
              </FieldWrapper>
            );

          case 'textarea':
            return (
              <FieldWrapper key={key} column={column}>
                <FormikTextArea
                  name={name}
                  label={label}
                  placeholder={placeholder}
                  apiError={apiError}
                  rows={meta?.textareaRows}
                />
              </FieldWrapper>
            );

          case 'divider':
            return (
              <DividerWrapper key={key}>
                <FormDivider>{label}</FormDivider>
              </DividerWrapper>
            );

          case 'selectable_tabs':
            return (
              <FieldWrapper key={key} column={column} hasOffset>
                <FormikSelectableTabs
                  label={label}
                  name={name}
                  disabled={disabled}
                  options={meta?.selectableTabsOptions}
                ></FormikSelectableTabs>
              </FieldWrapper>
            );

          case 'switch_toggle':
            return (
              <FieldWrapper key={key} column={column}>
                <FormikSwitchToggle name={name} label={label} disabled={disabled} size='small' />
              </FieldWrapper>
            );

          default: {
            const typeProps = getInputTypeAdditionalProps(type);

            return (
              <FieldWrapper key={key} column={column}>
                <FormikInput
                  label={label}
                  name={name}
                  type={type}
                  disabled={isDisabledField}
                  placeholder={placeholder}
                  errorsApi={apiError}
                  autoComplete={autoComplete}
                  isCopyPasteDisabled={isCopyPasteDisabled}
                  iconRight={meta?.iconRight}
                  labelSlot={meta?.labelSlot}
                  {...typeProps}
                />
              </FieldWrapper>
            );
          }
        }
      })}
    </FormGeneratorWrapper>
  );
};
